@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  border-radius: 5px;
}

.wrapword {
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.input{
  border: none;
  outline: none;
  color:white;
  background:none;
  border-bottom:2px solid white;
  transition: 150ms all ease;
  padding-top: 4px;
  padding-bottom: 4px;
  outline: none;
}
.input:hover{
  border-color: rgb(0, 170, 9);
  outline: none;
}
.input:active{
  outline: none;
  border-color: rgb(0, 170, 9);
}
.input:focus{
  outline: none;
}